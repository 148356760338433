<template>
    <div class="content-wrapper">
        <bo-page-title />
        <div class="content pt-0">
            <b-card id="holidayFilter">
            <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                <h3 class="font-weight-bold mb-0">{{ calendarYear }}</h3>
                <div class="ml-3">
                    <b-button @click="move('prev')" class="btn btn-primary mr-1"><i class="icon-arrow-left22"></i></b-button>
                    <b-button @click="move('next')" class="btn btn-primary"><i class="icon-arrow-right22"></i></b-button>
                </div>
                <div class="d-flex ml-3">
                    <div class="color-legend">
                    <div class="bg-danger rounded-circle p-2 d-inline-block mr-2 align-middle"></div>
                    <span>Hari Libur Tiap Tahun</span>
                    </div>
                    <div class="color-legend ml-4">
                    <div class="bg-info rounded-circle p-2 d-inline-block mr-2 align-middle"></div>
                    <span>Hari libur hanya tahun ini</span>
                    </div>
                </div>
                </div>
                <!--
                <b-button variant="outline-info" class="btn-rounded" @click="configHoliday()" v-if="moduleRole('add')">
                <i class="fas fa-plus mr-2"></i>
                Tambah Hari Libur
                </b-button>
                -->
            </div>
            </b-card>
            <b-card v-if="isList" no-body>
                <template #header>
                    <h5 class="card-title">Hari Libur</h5>
                </template>
                <div class="row p-2">
                    <div class="col-md-12">
                        <v-calendar
                            :rows="3"
                            :columns="4"
                            is-expanded
                            trim-weeks
                            :attributes="holidayAttributes"
                            locale="id"
                            ref="holidayCalendar"
                            :from-page="{ month: 1, year: calendarYear }"
                            :to-page="{ month: 12, year: calendarYear }"
                            @dayclick="dayClicked"
                        >
                            <template #header-title="data">
                            <span>{{ data.monthLabel }}</span>
                            </template>
                            <template #header-left-button><div class="d-none" /></template>
                            <template #header-right-button><div class="d-none" /></template>
                            <template #day-popover="data">
                            <div class="text-center mb-2">
                                <div>{{ data.day.date | moment('ll') }}</div>
                                <div v-for="(v, k) in data.attributes" :key="k">
                                <div class="d-flex align-items-center my-1">
                                    {{ v.customData.mhl_ket }}
                                    <div class="ml-2">
                                    <b-button
                                        variant="info"
                                        class="btn-icon"
                                        squared
                                        v-b-tooltip.hover.noninteractive.bottom="'Ubah Hari Libur'"
                                        @click="configHoliday(v)"
                                        v-if="moduleRole('edit')"
                                    >
                                        <i class="icon-pencil5"></i>
                                    </b-button>
                                    <b-button
                                        variant="outline-danger"
                                        class="btn-icon"
                                        squared
                                        v-b-tooltip.hover.noninteractive.bottom="'Hapus Hari Libur'"
                                        @click="deleteItem(v.customData.mhl_ket, v.customData.mhl_id)"
                                        v-if="moduleRole('delete')"
                                    >
                                        <i class="icon-bin"></i>
                                    </b-button>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </template>
                        </v-calendar>
                    </div>
                </div>
               
            </b-card>
        </div>
        <validation-observer ref="VFormSubmitHoliday">
            <b-modal
                v-model="holidayDetail"
                centered
                @ok.prevent="submitHoliday"
                size="sm"
                title="Atur Hari Libur"
                :no-enforce-focus="true"
                >
                <!--
                <h2>Ini modal detail</h2>
                <h3>{{row}}</h3>
                -->
                    <div class="row g-3">
                        <div class="col-md-12">
                            <div class="form-group mb-1">
                                <label for="">Tanggal Libur<span class="text-danger">*</span></label>
                                <h5>{{row.mhl_date |  moment("DD MMMM YYYY")}}</h5>
                            </div>
                            <div class="form-group mb-1">
                                <label for="">Keterangan hari Libur<span class="text-danger">*</span></label>
            
                                <b-form-input :formatter="normalText" v-model="row.mhl_ket"
                                type="text" class="form-control" />

                                <VValidate 
                                    name="Keterangan Hari Libur" 
                                    v-model="row.mhl_ket" 
                                    :rules="{required : 1, min: 2, max: 128}"
                                />  
                            </div>
                            <div class="form-group mb-1">
                                <label for="">Tipe hari libur<span class="text-danger">*</span></label>
                                <v-select placeholder="Pilih Tipe Hari Libur" v-model="row.mhl_type"
                                    :options="Config.mr.typeHariLibur" label="text" :clearable="true"
                                    :reduce="v=>v.value">
                                </v-select>
                                <VValidate 
                                    name="Tipe Hari Libur" 
                                    v-model="row.mhl_type" 
                                    :rules="{required : 1}"
                                />  
                            </div>
                        </div>
                    </div>
            </b-modal>
        </validation-observer>
    </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')
// import Datepicker from 'vuejs-datepicker'

export default {
  extends: GlobalVue,
  data() {
    return {
      holidayAttributes: [],
      calendarDates: [],
      tempHoliday: [],
      row: {},
      selectedId: '',
      hldTypeOptions: [
        { text: 'Hari Libur Tiap Tahun', value: '1' },
        { text: 'Hari libur hanya tahun ini', value: '2' },
      ],
      calendarYear: new Date().getFullYear(),
      displayYear: new Date().getFullYear(),
      modalType: 'add',
      holidayDetail: false
    }
  },
  mounted(){
    if(this.$route.params.pageSlug){
      this.validateModuleRoleCrud()
    }
    this.getHolidays()
  },
//   components:{
//     Datepicker
//   },
  methods: {
    configHoliday(data) {
      if(data){
        this.idSelected = data.customData.mhl_id
        this.row = data.customData
        this.modalType = 'update'
      } else {
        this.row = {}
        this.modalType = 'add'
      }
      this.holidayDetail = true
    },
    dayClicked(data) {
        if(moment(data.date, "YYYY-MM-DD").format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')){
            return this.$swal({
                title: "Tidak dapat melakukan konfigurasi hari libur di hari ini",
                icon: 'error',
                confirmButtonText: 'Ok',
            })
        }
        
        this.modalType = 'add'
        this.row = {}
        this.row.mhl_date = moment(data.date, "YYYY-MM-DD").format('YYYY-MM-DD')
        if (data.attributes.length == 0) this.holidayDetail = true
    },

    move(type){
      let moveTo
      this.loadingOverlay = true
      if (type == 'next') {
        moveTo = this.calendarYear += 1
      }else{
        moveTo = this.calendarYear -= 1
      }
      this.$refs.holidayCalendar.move(
        { month: 1, year: moveTo },
        { position: 1 },
      ).then(() => {
        this.holidayAttributes = []
        setTimeout(() => {
          this.getHolidays(this.calendarYear)
          this.loadingOverlay = false
        }, 0)
      })
    },
    getHolidays(year=new Date().getFullYear()){
        this.loadingOverlay = true
        Gen.apiRest(
            "/get/"+this.modulePage,
            {
                params: Object.assign({
                    year: year,
                })
            }
        ).then(res=>{
            this.loadingOverlay = false
            this.holidayAttributes = res.data.dataList      
        })
    },
    deleteItem(objName, id){
        this.$swal({
            title: `Yakin Ingin Menghapus Data "${objName||'-'}"?`,
            text: "Data yang telah dihapus tidak dapat dipulihkan kembali!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ya, hapus!',
            cancelButtonText: 'Tidak, batalkan'
        }).then(result => {
            let data = {
                id : id,
                type : 'delete'
            }
            this.loadingOverlay = true   
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                this.loadingOverlay = false
                let resp = res.data
                resp.statusType = 200
                this.$swal({
                    title: resp.message,
                    icon: resp.status,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then(result => {
                    if (result.value) {
                        this.getHolidays()
                    }
                })
            }).catch(err => {
                this.loadingOverlay = false
                if (err) {
                    err.statusType = err.status
                    err.status = "error"
                    err.title = err.response?.data?.title
                    err.message = err.response?.data?.message
                    err.messageError = err.message
                }
                this.doSetAlertForm(err)
            })
        })
    },

    submitHoliday(){
        this.$refs['VFormSubmitHoliday'].validate().then(success=>{
            let data = this.row
            data.type = this.modalType

            this.loadingOverlay = true   
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                this.loadingOverlay = false
                let resp = res.data
                resp.statusType = 200
                this.$swal({
                    title: resp.message,
                    icon: resp.status,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then(result => {
                    if (result.value) {
                        this.getHolidays()
                        this.holidayDetail = false
                    }
                })
            }).catch(err => {
                this.loadingOverlay = false
                if (err) {
                    err.statusType = err.status
                    err.status = "error"
                    err.title = err.response?.data?.title
                    err.message = err.response?.data?.message
                    err.messageError = err.message
                }
                this.doSetAlertForm(err)
            })
        })
    }

  },
  watch:{
    $route(){
      this.getHolidays()
    },
    '$route.params.pageSlug':function(){
      this.$set(this,'filter',{})
    }
  }
}
</script>